<template>
<CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <CRow>
              <CCol md="5">
                    <h4>{{ title }}</h4>
                    <router-link v-if="backtothelist" @click.native="backToList()" :to='"#"'><< Back to the List</router-link> 
              </CCol>
              <CCol md="5">
                   <CInput label="Search" v-model="filter" placeholder="Type to Search" horizontal />
              </CCol>
              <CCol md="2"> 
                   <b-button v-if="button" variant="primary" to="/cfactor/comb" >New Fee</b-button>
              </CCol>             
          </CRow>           
        </CCardHeader>
        <CCardBody>
            <CRow>
                  <CCol col="12" class="text-left">                   
                    <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
                  </CCol> 
            </CRow>  
            <CRow>
                  <CCol col="12" class="text-center">                   
                    <b-spinner variant="success" label="Spinning" v-if="spinner"></b-spinner>
                  </CCol> 
            </CRow> 

          <div class="table-responsive table">
	        <b-table striped hover  :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter">	        

                <template #cell(index)="{ item }">
                    {{ items.indexOf(item) + 1 }}
                </template>

                <template v-slot:cell(transactionFeeId)="{ item }">
                    <router-link @click.native="actionDetail(item.transactionFeeId )" :to='"#"'> 
                      {{ item.transactionFeeId }} 
                    </router-link>
                </template>  

                <template v-slot:cell(factorsCombination)="{ item }">
                    {{ item.factorsCombination }}                       
                </template>           

                <template v-slot:cell(createDate)="{ item }"> 
                      {{ item.createDate | dateWithTime }}
                      
                </template> 

                <template v-slot:cell(actions)="{ item }">
                  
                  <b-dropdown variant="info" text="Actions" size="md">
                    <router-link :to='"/cfactor/edit/" + item.id' tag="b-dropdown-item" > Edit </router-link>
                    <router-link to="#" exact v-on:click.native="confirmdelCombFactor(item.id)" tag="b-dropdown-item"> Delete </router-link>
                  </b-dropdown>

                </template>

	        </b-table>
          </div>

          <b-row>
            <b-col sm="3">
               <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" />
            </b-col>       
            <b-col sm="9">
               <b-form-select :options="[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]" v-model="perPage" style="width:100px;">
               </b-form-select>
            </b-col>
          </b-row>  

        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>  

<script>
import TrService from '@/api/TransactService.js';

 export default {
    name: "combfactor",
    data() {
      return {
        title:'Card fees combinations',
        backtothelist:false,
        button:true,
        items:[], 
        main:[],            
        fields: [],       
        currentPage: 1,
        perPage: 10,
        filter: null,        
        spinner:false,
        seen:false,
        msg :'',
        color:''
      };
    },
    directives: {
        dateSubstr: {
            update(el) {
                var temp = el.value.toString().split(".");
                el.value =  temp[0].replace("T"," ");
            },
        },        
    },   

    filters: {
        upper(value){
          if(!value) return ''
          value = value.toString()
          return value.toUpperCase()
        },
        lower(value){
           if(!value) return ''
           value = value.toString()
           return value.toLowerCase()        
        },
        dateSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,10)         
        },
        dateWithTime(value){
           if(!value) return ''
           var temp = value.toString().split(".");
           value = temp[0].replace("T"," ");
           return value;            
        },
        IdSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,5) + '...'            
        },
        changeToList(value){
          if(!value) return ''
          value = value.replace("{","").replace("}","")
          var rows = value.split(",")          
          // var res  = [];
          // for (var key in rows) {
          //     var val = rows[key];
          //     var row = "<li>"+val+"</li>";
          //     res.push(row);
          // }
          // var list = res.join("/n");
          // var result = "<ul>"+list+"</ul>";
          return rows
        }
        
    },

    created () {   
       this.isSuperAdmin();    
       this.getMainFields(); 
       this.getListCombFactor();     
    },
    methods: { 

      confirmdelCombFactor(id){          
          if (confirm('Are you sure you want to delete ? ')) { 
             this.delCombFactor(id);
          }
      },

      delCombFactor: function(id) {
        TrService.delCombFactor(id).then(resp => {  //console.log(resp);
        
            if(resp.message === "Success"){ 
                alert("Delete fee combination Success!");                             
                this.getListCombFactor();
            } else {
              this.msg   =  !resp.data.message ? "Delete fee combination Failed!" : resp.data.message; 
              this.color = 'danger'; this.seen  = true;              
            }  
            setTimeout( () =>  this.seen=false , 5000);         

        }, error => {
          this.spinner = false;
        });
      },

      backToList(){ 
          this.backtothelist = false; 
          this.button = false; 
          this.resetPagination();
          this.getMainFields();  
          this.items = this.main;    
          this.title = 'Card fees combinations';  
          this.button = true;     
      },

      resetPagination(){
         this.perPage = 10; this.currentPage = 1;
      },

      getMainFields(){
        let result =   [{ key: 'index',label : '#'},
                        {key: 'transactionFeeId',label : 'Transaction Fee Id', sortable: true}, 
                        {key: 'factorsCombination',label : 'Factors Combination',sortable: true},          
                        {key: 'createDate',label : 'Created Date',sortable: true}, 
                        {key: 'actions',label: 'Actions' }]; 

        this.fields = result;
      }, 
      
      getDetailFields(){

          let result =  [{key: 'id',label : 'ID',sortable: true}, 
                         {key: 'transactionType',label : 'Transaction Type',sortable: true},
                         {key: 'userGroupUniqueNumber',label : 'User Group',sortable: true}, 
                         {name : 'feeType',key: 'feeType',label : 'Fee Type',sortable: true}, 
                         {key: 'amount',label : 'Amount',sortable: true},          
                         {key: 'recordStatus',label : 'Status',sortable: true},    
                         {key: 'createDate',label : 'Created Date',sortable: true}]; 

          this.fields = result;
      },

      actionDetail(id){ 
        let routeData = this.$router.resolve({path: '/settings/fee', query: {feeId: id }});
        window.open(routeData.href, '_blank');
        //this.findTrFee(id);
      },

      isSuperAdmin(){
        if(localStorage.getItem('sa')==="false"){  
            this.$router.push('/dashboard');
        }
      },

      stringToBoolean: function(string){
          switch(String(string).toLowerCase().trim()){
              case "true": case "yes": case "1": return true;
              case "false": case "no": case "0": case null: return false;
              default: return Boolean(string);
          }
      },  
      
      findTrFee: function(feeId) { 
          TrService.findTrFee(feeId).then(resp => {  //console.log(resp);

              this.title = "Transactions Fee";  
              this.main = this.items
              this.getDetailFields(); this.items=[];
              this.items.push(resp);           

              this.backtothelist = true;    this.button = false; 

          }, error => {
              console.log('error');    
          });
      },

      getListCombFactor: function() {
        TrService.getListCombFactor().then(resp => {  //console.log(resp);     
            this.items = resp; 
        }, error => {
          this.spinner = false;
        });
      },

    }   
 }; 

</script>
<style>
    .table{ overflow-y:scroll; }
</style>