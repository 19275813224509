var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('CCard',[_c('CCardHeader',[_c('CRow',[_c('CCol',{attrs:{"md":"5"}},[_c('h4',[_vm._v(_vm._s(_vm.title))]),(_vm.backtothelist)?_c('router-link',{attrs:{"to":"#"},nativeOn:{"click":function($event){return _vm.backToList()}}},[_vm._v("<< Back to the List")]):_vm._e()],1),_c('CCol',{attrs:{"md":"5"}},[_c('CInput',{attrs:{"label":"Search","placeholder":"Type to Search","horizontal":""},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1),_c('CCol',{attrs:{"md":"2"}},[(_vm.button)?_c('b-button',{attrs:{"variant":"primary","to":"/cfactor/comb"}},[_vm._v("New Fee")]):_vm._e()],1)],1)],1),_c('CCardBody',[_c('CRow',[_c('CCol',{staticClass:"text-left",attrs:{"col":"12"}},[(_vm.seen)?_c('CAlert',{attrs:{"color":_vm.color,"id":"alert"}},[_vm._v(_vm._s(_vm.msg))]):_vm._e()],1)],1),_c('CRow',[_c('CCol',{staticClass:"text-center",attrs:{"col":"12"}},[(_vm.spinner)?_c('b-spinner',{attrs:{"variant":"success","label":"Spinning"}}):_vm._e()],1)],1),_c('div',{staticClass:"table-responsive table"},[_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.items,"fields":_vm.fields,"current-page":_vm.currentPage,"per-page":_vm.perPage,"filter":_vm.filter},scopedSlots:_vm._u([{key:"cell(index)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.items.indexOf(item) + 1)+" ")]}},{key:"cell(transactionFeeId)",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":"#"},nativeOn:{"click":function($event){return _vm.actionDetail(item.transactionFeeId )}}},[_vm._v(" "+_vm._s(item.transactionFeeId)+" ")])]}},{key:"cell(factorsCombination)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.factorsCombination)+" ")]}},{key:"cell(createDate)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateWithTime")(item.createDate))+" ")]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-dropdown',{attrs:{"variant":"info","text":"Actions","size":"md"}},[_c('router-link',{attrs:{"to":"/cfactor/edit/" + item.id,"tag":"b-dropdown-item"}},[_vm._v(" Edit ")]),_c('router-link',{attrs:{"to":"#","exact":"","tag":"b-dropdown-item"},nativeOn:{"click":function($event){return _vm.confirmdelCombFactor(item.id)}}},[_vm._v(" Delete ")])],1)]}}])})],1),_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('b-pagination',{attrs:{"size":"md","total-rows":this.items.length,"per-page":_vm.perPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1),_c('b-col',{attrs:{"sm":"9"}},[_c('b-form-select',{staticStyle:{"width":"100px"},attrs:{"options":[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }